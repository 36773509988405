import React, { useEffect, useState } from 'react';
import { getFieldList, manuallyCreateUser } from '../../services/user.service';
import { Button, makeStyles, TextField, Typography, Grid, Select, MenuItem, InputLabel } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { includes } from 'lodash';

const AddHCPAction = ({ data }) => { 
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorInputMessage, setErrorInputMessage] = useState(null);
  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 10,
      width: '100%'
    },
    select: {
      width: '100%'
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();

  const { user_type, mobile_number, first_name, middle_name, last_name, email } = data;
  const [userDetails, setUserDetails] = useState({
    mobile_number,
    first_name, 
    middle_name, 
    last_name, 
    email,
    user_type
  });
  const [listOfDispensingChannel, setListOfDispensingChannel] = useState();
  const [listOfHospital, setListOfHospital] = useState();
  const nodeEnv = `${window.config.NODE_ENV}`;
  const programId = `${window.config.PROGRAM_ID}`;
  console.log(window.config);

  useEffect(() => {
    const getAllDispensingChannel = async () => {
      const { result } = await getFieldList({
        program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
        name: 'outlet_name'
      });

      let dispensingChannel = [];

      if (result && result.field_type_options) {
        dispensingChannel = result.field_type_options.select_options;
      }

      dispensingChannel.unshift({ label: 'NA', value: 'NA' });

      setListOfDispensingChannel(dispensingChannel);
    }

    const getAllHospital = async () => {
      const { result } = await getFieldList({
        program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
        name: 'hospital'
      });

      let hospitals = [];

      if (result && result.field_type_options) {
        hospitals = result.field_type_options.select_options;
      }

      hospitals.unshift({ label: 'NA', value: 'NA' });

      setListOfHospital(hospitals);
    }

    getAllDispensingChannel();
    getAllHospital();
  }, []);

  const onClick = async () => {
    setOpenModal(true);
  };

  const onSubmit = async() => {
    try {
      setLoading(true);
      const { result } = await manuallyCreateUser({
        program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
        payload: {
          user_type: userDetails.user_type,
          mobile_number: userDetails.mobile_number,
          email: userDetails.email,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          middle_name: userDetails.middle_name,
          full_name: `${userDetails.first_name} ${userDetails.middle_name} ${userDetails.last_name}`,
          outlet_name: userDetails.outlet_name || undefined,
          hospital: userDetails.hospital || undefined,
          communication_channel: userDetails.communication_channel || undefined
        }
      });

      if (result) {
        setOpenModal(false);
        setConfirmation(true);
      } else {
        setUserDetails({
          mobile_number,
          first_name, 
          middle_name, 
          last_name, 
          email,
          user_type
        });
      }
    } catch(err) {
      if (err && err.inputs) {
        setErrorInputMessage(err.inputs);
      } else if (err && err.message) {
        alert(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    setUserDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={12}>
            <TextField  
              label='Mobile Number'
              required={true}
              name='mobile_number' 
              defaultValue={userDetails.mobile_number}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput} 
              placeholder='60XXXXXXXXX'
            />
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'
            >
              { errorInputMessage ? errorInputMessage.mobile_number : '' }
            </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='First Name'
            name='first_name' 
            defaultValue={userDetails.first_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'
          >
          { errorInputMessage ? errorInputMessage.first_name : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='Last Name'
            name='last_name' 
            defaultValue={userDetails.last_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'
            >
            { errorInputMessage ? errorInputMessage.last_name : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
         <Grid 
          item xs={12}
        >
          <TextField  
            label='Email Address'
            name='email' 
            defaultValue={userDetails.email}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
          />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'
            >
            { errorInputMessage ? errorInputMessage.email : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Hospital</InputLabel>
          <Select
            required={true}
            label='Hospital'
            name="hospital"
            variant='outlined' 
            className={classes.field}
            onChange={onFieldInput}
          >
             {
                listOfHospital && listOfHospital.length ? listOfHospital.map(hospital => {
                  return <MenuItem className={classes.menuItem} key={hospital.value} value={hospital.value}>
                  {hospital.label}
                </MenuItem>
                }) : <></>
              }
            </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Outlet Name</InputLabel>
          <Select 
              required={true}
              label='Outlet Name'
              name="outlet_name"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
              {
                listOfDispensingChannel && listOfDispensingChannel.length ? listOfDispensingChannel.map(dispensingChannel => {
                  return <MenuItem className={classes.menuItem} key={dispensingChannel.value} value={dispensingChannel.value}>
                  {dispensingChannel.label}
                </MenuItem>
                }) : <></>
              }
            </Select>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
         <Grid item xs={6}>
            <InputLabel>User Type</InputLabel>
            <Select 
              required={true}
              label='User Type'
              name="user_type"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
                <MenuItem className={classes.menuItem} key="doctor" value="doctor">
                  Doctor
                </MenuItem>
                <MenuItem className={classes.menuItem} key="pharmacist" value="pharmacist">
                  Pharmacist
                </MenuItem>
            </Select>
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'
            >
              { errorInputMessage ? errorInputMessage.user_type : '' }
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <InputLabel>Comm Channel</InputLabel>
            <Select 
              required={true}
              label='Preferred Communication Channel'
              name="communication_channel"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
                <MenuItem className={classes.menuItem} key="whatsapp" value="whatsapp">
                  Whatsapp
                </MenuItem>
                <MenuItem className={classes.menuItem} key="sms" value="sms">
                  SMS
                </MenuItem>
                <MenuItem className={classes.menuItem} key="email" value="email">
                  Email
                </MenuItem>
            </Select>
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'
            >
              { errorInputMessage ? errorInputMessage.user_type : '' }
            </Typography>
        </Grid>
      </Grid>
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
    </>
  );

  const handleClose = (reload) => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setUserDetails({
      mobile_number,
      first_name, 
      middle_name, 
      last_name, 
      email,
      user_type
    });

    if (reload) {
      window.location.reload();
    }
  }

  const formButtons = (
    <>
      <Button onClick={() => handleClose(false)} variant='contained'>
        Close
      </Button>
      <Button onClick={onSubmit} variant='contained' color='secondary'>
        Submit
      </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog
      key='create-hcp'
      open={openModal}
      title = 'Create HCP'
      content = {formContent} 
      children = {formButtons} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(false)} 
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = 'HCP successfully created!' 
      children = {(
        <Button onClick={() => handleClose(true)} variant='contained'>
          Close
        </Button>
      )} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(true)} 
      />
     <Button variant="contained" color="primary" onClick={onClick} 
     style={{ marginRight: 8 }}>
      + ADD HCP</Button>
    </>
  )
}

export default AddHCPAction;
